import React, { useState, useEffect, useContext ,useRef} from "react";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';


export default function TransactionsDrawer({data}) {
  const [state, setState] = React.useState({
    right: false,
  });






  const toggleDrawert = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 320 }}
      role="presentation"
     
    >
      
       <div className="row justify-content-center">
         <div className="row ">
            <div className="col-12 mt-2 px-2">
                 <Typography variant="h6" gutterBottom>
                 Dernières  Transactions
                </Typography>
            </div>
             
            </div>
        </div>
      <Divider />
   
      <List className="px-2">
        {data.map((el, index) => (
            <div className="trans_item" key={index}>
          <ListItem   disablePadding onClick={toggleDrawert(anchor, false)}>
            
              
              <ListItemText primary={el.phone}  secondary={<span className={ el.phone.substring(0,2) =='06' ? 'contact_badge mobilis' : el.phone.substring(0,2) == '07' ? 'contact_badge djezzy' : 'contact_badge ooredoo'}>
                {el.phone.substring(0,2) =='06' ? 'Mobilis' : el.phone.substring(0,2) == '07' ? 'Djezzy' : 'Ooredoo'}
              </span>}/>
              
              <span className={'latest_transprimary_text'}>
                {el.amount} DA
                
              </span>
              
              
          </ListItem>
          <span>
                {el.created_at} 
          </span>
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button className='transactionbtn' onClick={toggleDrawert(anchor, true)}>{'Transactions'}</Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawert(anchor, false)}
            onOpen={toggleDrawert(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}