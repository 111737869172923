import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { nFormatter } from "../helpers";

export default function Statistics({ balance, sent, pts }) {
  const { t } = useTranslation();
  return (
    <div className="container ">
      <h1 className="pagetitle text-center ">Statistiques du jours</h1>

      <div className="cards row justify-content-center">
        <div className="col-4 col-md-3 mb-3 statistics-wrap">
          <div className={balance <= 5000 ? "card statistics element_shadow  red_stat": "card   statistics element_shadow"}>
            <div className="card-body text-center">
              <h3 className={balance <= 5000 ? "stat-item-title red_txt":"stat-item-title"}> {nFormatter(balance)}</h3>
              <label className={balance <= 5000 ? "stat-item-subtitle red_txt" :"stat-item-subtitle"}>Solde (DA)</label>
            </div>
          </div>
        </div>
        <div className="col-4 col-md-3 mb-3 statistics-wrap">
          <div className="card   statistics element_shadow">
            <div className="card-body text-center">
              <h3 className="stat-item-title">{nFormatter(sent)}</h3>
              <label className="stat-item-subtitle">Envoyé (DA)</label>
            </div>
          </div>
        </div>
        <div className="col-4 col-md-3 mb-3 statistics-wrap">
          <div className="card   statistics element_shadow">
            <div className="card-body text-center">
              <h3 className="stat-item-title">{nFormatter(pts)}</h3>
              <label className="stat-item-subtitle">Points (DA)</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
