import React, { useState, useEffect, useRef, useContext } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import Button from "@mui/material/Button";

export default function HistoricItem({ data, type,handelDebtModal }) {
  function rechargement(type) {
    var link =
      "https://paiement.algerietelecom.dz/index.php?p=voucher_internet&produit=4g";
    if (type == "ADSL") {
      link =
        "https://paiement.algerietelecom.dz/index.php?p=voucher_internet&produit=in";
    }
    /*navigation.navigate("ActivateCarte", {
      cardnumber: code,
      link: link,
      type: type
    });*/
  }
  return (
   
    
    <div className="col-md-12 mb-3">
      <div className="card w-100 transaction-item">
        <div className="card-body ">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="phone">
                {" "}
                {type == "flexy" ? data.phone : data.code}
              </div>
              <div>
                <span className="dealer ooredoo px-2">
                  {type == "flexy" ? data.network : data.type}
                </span>
                {/*type == "idoom" ? (
                  <Button
                    variant="contained"
                    disableElevation={true}
                    target="_blank"
                    component="a"
                    href={
                      data.type == "4g"
                        ? "https://paiement.algerietelecom.dz/index.php?p=voucher_internet&produit=4g"
                        : "https://paiement.algerietelecom.dz/index.php?p=voucher_internet&produit=in"
                    }
                    className="dealer_recharge ooredoo px-2"
                  >
                    Rechargement
                  </Button>
                  ) : null*/}
              </div>
              <div className="transaction-date pt-2">
               DATE:{" "}
                {data.created_at}
                <span>
                  {" "}
                  SOLDE :{" "}
                  {data.remaing_balance} DA
                </span>
              </div>
            </div>
            <div>
              <div className="amount text-end">{data.amount} DA</div>
            </div>
          </div>
          {type == "flexy" &&<button type="button" className="btn add_to_dette" onClick={()=>{
          handelDebtModal(data)
          }}>Marquer comme dette</button>}
        </div>
      </div>
    </div>


  );
}
