import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
export default function OperatorSelectionButton({
  imgPath,
  imgClass,
  path,
  keydown,title,status
}) {
  const { t } = useTranslation();

  return (
    <Link href={path} className="col-6 col-md-4 operator_btn_home ">
      <div className="card  ">
        <div className="card-body text-center  element_shadow">
         {keydown != null && (<div className="keydown">
            Appuyer sur
            <img src={keydown} />
          </div>)} 
          {title == null ? (
          <img src={imgPath}  className={imgClass} />
          
          ) :(
            <div className="card-body mt-2">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">بطاقات التعبئة</p>
            <p className="card-text">500 DA - 1000 DA - 2000 DA</p>
            <img src={imgPath} className={"floating_card_img"} />
            </div>
          )}
           {!status  && (<div className="operatorstatus">
            OFF
          </div>)}
        </div>
       
      </div>
    </Link>
  );
}
