import React, { useState, useEffect, useRef, useContext } from "react";
import { nFormatter } from "../../helpers";

export default function RechargeItem({ data }) {


  return (
 
    <div className="col-md-12 mb-3 ">
      <div className="card rechargement_card transaction-item " >
      <div className="row ">
            <p className="text-end mb-0">{data.created_at}</p>
          </div>
        <div className={data.unpaid == null ? "card-body pay_row" : data.unpaid > 0 ? "card-body unpaid_row" : "card-body paid_row"}>
         {data.balance != null ? (
          <div className=" row d-sm-flex align-items-center justify-content-between">
            <div className="text-center  col-sm-4">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Rechargement</p>
              <p className="recharge_item_amount">{nFormatter(data.balance)} DA</p>
              </div>
            </div>

            <div className="text-center col-sm-4">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Payé</p>
              <p className="recharge_item_amount">{nFormatter(data.paid)} DA</p>
              </div>
            </div>

            <div className="text-center col-sm-4">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">No payé</p>
              <p className="recharge_item_amount">{nFormatter(data.unpaid)} DA</p>
              </div>
            </div>
            
          </div>) :(
            <div className=" row d-sm-flex align-items-center justify-content-between">
            <div className="text-center  col-sm-4">
              <div className="recharge_item text-center">
              <p className="payment_item_subtitle">{nFormatter(data.amount)} DA</p>
              <p className="payment_item_amount">{data.type == 0 ? 'Versement (app)' : 'Versement (puces)'}</p>
              </div>
            </div>
            <div className="text-center  col-sm-4">
              <div className="recharge_item text-center">
              <img src="images/success.png" className="pay_icon"/>
            </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}
