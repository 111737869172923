import React, { useState, useEffect, useRef, useContext } from "react";

export default function DebtsComponent({ data ,handleDelete}) {

  return (
    
    <div className="col-md-4 mb-3">
      <div className="card w-100 transaction-item">
        <div className="card-body ">
          <div className="d-flex align-items-center justify-content-between">
            <div>
            <div className="name">
                {" "}
                {data.name}
              </div>
              <div className="phone">
                {" "}
                {data.phone}
              </div>
              <div>
                <span className="dealer ooredoo px-2">
                  {data.type}
                </span>
                
              </div>
              <div className="transaction-date pt-2">
               DATE:{" "}
                {data.created_at}
                
              </div>
            </div>
            <div>
              <div className="amount text-end">{data.amount} DA</div>
            </div>
          </div>
          <button type="button" className="btn delete_btn" onClick={()=>{
            handleDelete(data.id)
          }}>Supprimer</button>
        </div>
      </div>
    </div>
    
  );
}
