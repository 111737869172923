import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
  createContext
} from "react";
import Avatar from "@mui/material/Avatar";
import { Button, CircularProgress } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TWS } from "../../services/WebServices";
import Copyright from "../../components/Copyright";
import { makeStyles } from "@mui/styles";
import { AuthContext } from "../../providers/AuthStack";

import { db, auth } from "../../services/Firestore";

import {
  collection,
  query,
  where,
  setDoc,
  doc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import getDeviceFingerprint from '../../utils/getDeviceFingerprint';
import ErrorDialog from '../../components/ErrorDialog';


const theme = createTheme();

export default function Login() {
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const { authDispatch } = useContext(AuthContext);
  const deviceid = Date.now().toString();
  const [isVerified, setisVerified] = useState(true);
  const [otpModal, setotpModal] = useState(false);
  const [final, setfinal] = useState("");
  const [otp, setotp] = useState("");
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const signIn = async () => {
    
    try {
      setLoadingbtn(true)
      await TWS.postLogin(phone, password,).then(res => {
        /*if (!isVerified) {
          setToken(res.token);
          setotpModal(true);
          onSubmit();
        } else {
          
          authDispatch({ type: "SIGN_IN", token: res.token });
          
          FirebaseRegisterDevice(deviceid,res.data.id)
        }*/
       setUserData(res)
       checkfingerPrint(res,fingerprint)
      });
    } catch (errors) {
      //alert("Erreur d'authentification");
      setmsgErrors("Erreur d'authentification, Le numéro de téléphone ou le mot de passe est incorrect");
      setshowErrors(true)
      setLoadingbtn(false)
    }
  };


/**
 *  fingerprint
 */
const [codePin, setCodePin] = useState(null);
const [showcodePinField, setshowcodePinField] = useState(false);

const [fingerprint, setFingerprint] = useState(null);
const fetchFingerprint = async () => {
     const deviceFingerprint = await getDeviceFingerprint();
     setFingerprint(deviceFingerprint);
     //console.log(deviceFingerprint)
     //checkFingerPrint(deviceFingerprint)
 };
 useEffect(() => {
   fetchFingerprint();
 }, []);


function checkfingerPrint(data,fingerprint){
 
 TWS.checkFingerPrint(data.data.id,data.data.token,fingerprint)
     .then(res => {
      
       if(res){
       
        authDispatch({ type: "SIGN_IN", token: data.token });
        FirebaseRegisterDevice(deviceid,data.data.id)
       }else{
        setshowcodePinField(true)
       }
       setLoadingbtn(false)
     })
     .catch(() => {});
} 

function checkPin(){
  setLoadingbtn(true)
  TWS.checkpin(userData.data.id,userData.data.token,codePin,fingerprint)
 
     .then(res => {
      
       if(res){
        authDispatch({ type: "SIGN_IN", token: userData.token });
        FirebaseRegisterDevice(deviceid,userData.data.id)
       }else{
        //alert("Code Pin n'est pas Valide (الرمز غير صحيح)");
        setmsgErrors("Code Pin n'est pas Valide (الرمز غير صحيح)");
        setshowErrors(true)
       }
       setLoadingbtn(false)
     })
     .catch(() => {
      //alert("Code Pin n'est pas Valide (الرمز غير صحيح)");
      setmsgErrors("Code Pin n'est pas Valide (الرمز غير صحيح)");
      setshowErrors(true)
     });
}


  useEffect(() => {
   
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId == null) {
        asyncLocalStorage.setItem("deviceId", JSON.stringify(deviceid));

        FirebaseInitilaisationRequests();
        FirebaseInitilaisationResponses();
      }
    });
  }, []);

  useEffect(() => {
    asyncLocalStorage.getItem("uid").then(uid => {
      if (uid == null) {
        //setisVerified(false);
        // remove this line later
        asyncLocalStorage.setItem("uid", JSON.stringify("1234ZEDGHYTKKVKFJKDLLLDJ3"));
      }
    });
  }, []);

  const asyncLocalStorage = {
    setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };

  const onSubmit = async () => {
    const verify = new RecaptchaVerifier(
      "sign-in-button",
      { size: "invisible" },
      auth
    );

    await signInWithPhoneNumber(auth, "+213" + phone, verify)
      .then(confirmResult => {
        setfinal(confirmResult);
      })
      .catch(error => {
        alert(error.message);
        window.location.reload();
      });
  };
  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then(result => {
        //console.log(result);
        asyncLocalStorage.setItem("uid", JSON.stringify(result.user.uid));
        authDispatch({ type: "SIGN_IN", token: token });
      })
      .catch(err => {
        alert("code n'est pas valide (الرمز غير صحيح)");
      });
  };

  /**
   * update response status to avoid errors
   */

  const FirebaseInitilaisationRequests = async () => {
    try {
      const docref = doc(db, "requests", deviceid);

      await setDoc(docref, {
        status: "false"
      }).then(() => {
        // console.log("inti");
      });
    } catch (errors) {
      //console.log(errors);
    }
  };

  const FirebaseInitilaisationResponses = async () => {
    try {
      const docref = doc(db, "responses", deviceid);

      await setDoc(docref, {
        status: "false"
      }).then(() => {
        // console.log("inti");
      });
    } catch (errors) {
      //console.log(errors);
    }
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };



// register the user device

  const FirebaseRegisterDevice = async (device_id,user_id) => {
 
    try {
    const docref = doc(db, "userdevices", device_id);

      await setDoc(docref, {
         user :user_id,
        device: navigator.userAgent,
      }).then(() => {
       
      });
    } catch (errors) {
     // console.log(errors);
    }
  }

  // handelerror
  const [showErrors, setshowErrors] = useState(false);
  const [msgErrors, setmsgErrors] = useState('');

  const handelErrorDialog = () => {
    setmsgErrors('')
    setshowErrors(!showErrors)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="login_container">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            src="images/log_google_play.png"
            width={'100'}
          />

          <h3 className="login_title" >
            Se Connecter
          </h3>

          
          <Box noValidate>
         
           
            <TextField
            className="login_input"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Téléphone"
              name="phone"
              autoComplete="phone"
              autoFocus
              onChange={event => {
                setPhone(event.target.value);
              }}
            />
            <FormControl fullWidth sx={{ mt: 3 }} variant="outlined">
              <InputLabel className="login_input_label" htmlFor="outlined-adornment-password">
                Mot de passe
              </InputLabel>
              <OutlinedInput
              className="login_input"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={event => {
                  setPassword(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label={"Mot de passe"}
              />
            </FormControl>
            

            {showcodePinField && (
              <>
               
            <TextField
            className="login_input"
              margin="normal"
              required
              fullWidth
              id="pin"
              label="PIN"
              name="pin"
              autoComplete="pin"
              autoFocus
              onChange={event => {
                setCodePin(event.target.value);
              }}
            />
            <span className="input_msg">
               Veuillez introduire le code Pin
             </span>
            </>)}

          <Button
              onClick={() => {
                if (loadingbtn) return; // Prevent multiple clicks while loading
                if (showcodePinField) {
                  checkPin()
                } else {
                  signIn();
                }
                
              }}
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 2, height: 45, borderRadius: 20 }}
              className="main_btn"
              id="sign-in-button"
              disabled={loadingbtn}
            >
               {loadingbtn ? <CircularProgress size={24} color="inherit" /> : 'Connexion'}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Dialog open={otpModal}>
          <DialogTitle>Compte vérification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Entrez le code envoyé à votre numéro
            </DialogContentText>
            <DialogContentText>
              يرجى ادخال رمز التحقق الذي تم ارساله الى رقم هاتفك
            </DialogContentText>
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoComplete="code"
              autoFocus
              onChange={event => {
                setotp(event.target.value);
              }}
            />
            <Box
              sx={{
                width: 300
              }}
            >
              <div id="recaptcha-container"></div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              sx={{ mb: 2, height: 40, borderRadius: 20 }}
              className="main_btn"
              onClick={() => {
                ValidateOtp();
              }}
            >
              Vérification
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <ErrorDialog
            errordialog={showErrors}
            handelerrordialog={handelErrorDialog}
            message={msgErrors}
           
          />
    </ThemeProvider>
  );
}

const useStyles = makeStyles({
  main_btn: {
    borderRadius: 20,
    height: 45
  }
});
