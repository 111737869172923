import React, { useState, useEffect, useRef, useContext } from "react";

import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";

import { TWS } from "../../services/WebServices";
import DebtsComponent from "../../components/DebtsComponent";

import SendLoading from "../../components/flexy/SendLoading";




const Debtspage = props => {
  const { t } = useTranslation();
  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);
  const { user } = useContext(UserContext);
  const [Totaldebts, setTotaldebts] = useState(0);



  useEffect(() => {
    getDebtList();
  }, []);


 const handleDelete =(item)=>{

  TWS.deleteDebts(user.id, user.token, item)
  .then(res => {
    if (res = 'success') {
      
      getDebtList();

    }
    
  })
  .catch(() => {
    //TWS.onUnauthorized();
  });
 }

 function getDebtList(){
  setloading(true);
  TWS.getDebts(user.id, user.token)
      .then(res => {
        
        setlist(res.data);
        setTotaldebts(res.total)
        setloading(false);
      })
      .catch(() => {
        //TWS.onUnauthorized();
      });
 }










  return (
    <div className="wrapper">
      <Navbar active="debts" props={props} data={user} />
      {/* main */}
      <div className="main-content pb-5 transactions-page">
        <div className="container">
          <h1 className="pagetitle_intern text-center">{t("Transactions en Dettes")}</h1>
          
          <SendLoading loading={loading} />
          <div className='mb-4'>
            <span className="total_dette">
              Total : {Totaldebts} DA
            </span>
          </div>
          <div className="row">
          {list.map((item, index) => {
                    return (
                      <DebtsComponent key={index} data={item} handleDelete={handleDelete} />
                    );
          })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debtspage;
