import React, { useState, useEffect, useRef, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate
} from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";

import Inputs from "../components/flexy/Inputs";
import FloatingButton from "../components/flexy/FloatingButton";
import Colors from "../constants/Colors";
import Offers from "../components/flexy/Offers";
import { TransactionsContext } from "../constants/hoop";
import { formatnetworkCode } from "../helpers";
import { db } from "../services/Firestore";
import { TWS } from "../services/WebServices";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import BottomDrawerModal from "../components/flexy/BottomDrawerModal";
import ErrorDialog from "../components/ErrorDialog";
import Button from "@mui/material/Button";
import ContactDrawer from "../components/flexy/ContactDrawer";
import Offerdescription from "../components/flexy/Offerdescription";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import TransactionsDrawer from '../components/flexy/TransactionsDrawer'

const Flexy = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, updateUserInfos } = useContext(UserContext);
  const {
    settransactionussdcode,
    settransactionbalance,
    settransactionphone,
    setselectedchoise,
    transactionbalance,
    transactionussdcode,
    selectedchoise,
    transactionphone
  } = useContext(TransactionsContext);
  const [phone, setPhone] = useState("");
  const [balance, setBalance] = useState("");
   const [CustomerName, setCustomerName] = useState("");
  const [ussdCodeRequest, setUssdCodeRequest] = useState(null);
  const { operator,isnewoperation } = useParams();
  const [entities, setEntities] = useState([]);
  const [is_sentRequest, setIs_sentRequest] = useState(0);
  let [ShowModelResponse, setShowModelResponse] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);
  const [buttondisabled, setbuttondisabled] = useState(true);
  const [ussdscodesflexy, setussdscodesFlexy] = useState([]);
  const [ussdscodesoffer, setussdscodesOffre] = useState([]);
  const [ussdscodesrequestOffer, setussdscodesrequestOffer] = useState([]);
  const [ussdscodessamanet, setussdscodesSamanet] = useState([]);
  const [ussdforModalresponse, setussdforModalresponse] = useState(null);
  const [loadingussd, setloadingussd] = useState(false);
  const [enablebalanceInput, setsenablebalanceInput] = useState(true);
  const [selectedoffer, setSelectedOffer] = useState(null);
  const [errordialog, seterrordialog] = useState(false);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [dataloading, setdataloading] = useState(false);
  const [conncetionStatus, setconncetionStatus] = useState(true);
  const [operationType, setoperationType] = useState("flexy"); // flexy or activation (for ready buttons)
  const [deviceId, setDeviceId] = useState(null);

  
 
  /**
   * sucess response handling
   */
  /*const simcardSucessResponse = useRef(
    operator == 1
      ? 't  transf r  avec succ s'
      : operator == 2
      ? 'Un message de confirmation sera envoy'
      : 'Ooredoo',
  );*/

  /*const simcardSucessResponse = [
    "t  transf r  avec succ s",
    "Un message de confirmation sera envoy",
    "TRANSFERE DE VOTRE COMPTE",
    "Dinar realisee",
    "OK num",
    "credit transmis"
  ];*/

  /**
   * check if new operation
   */

  useEffect(() => {
  
   if (isnewoperation == 'true') {
    setPhone('')
   }else{
    setPhone(transactionphone);
    setbuttondisabled(false)
   }
  }, []);

  const [simcardSucessResponse, setsimcardSucessResponse] = useState([]);
  useEffect(() => {
    TWS.getSucessMessages(user.token)
      .then(res => {
        setsimcardSucessResponse(res.mgs);
      })
      .catch(() => {
        setsimcardSucessResponse([
          "t  transf r  avec succ s",
          "Un message de confirmation sera envoy",
          "TRANSFERE DE VOTRE COMPTE",
          "Dinar realisee",
          "OK num",
          "credit transmis",
          "PAYEMENT DE FACTURE",
          "PAIEMENT DE FACTURE"
        ]);
      });
  }, []);

  function checksuccess(msg) {
    let check = false;
    for (let i = 0; i < simcardSucessResponse.length; i++) {
      check = msg.includes(simcardSucessResponse[i]);
      if (check) {
        break;
      }
    }

    return check;
  }

  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  const handleNumberInputChange = number => {
    setPhone(number);
    settransactionphone(number); //context
    if (number != '' && lastTransaction == number ) {
      setis_duplicated(true)
    }else{
      setis_duplicated(false)
    }
    if (number.toString().length == 8) {
      setsenablebalanceInput(false);
      setbuttondisabled(false);
    } else {
      setbuttondisabled(true);
    }
  };

  const handleBlanceInputChange = value => {
    setBalance(value);
    settransactionbalance(value); //context
    let ussd = ussdscodesflexy[0].code;
    let code = null;

    code = ussd.replace("amount", value);

    setUssdCodeRequest(code);
    /*if (parseInt(value) > 40) {
      setUssdCodeRequest(null);
    }*/
  };
 const handelCustomerName = value => {
    setCustomerName(value);
 }
  

  const handelModalResponse = value => {
    setShowModelResponse(value);

    if (value) {
      setEntities([]);
    }
  };

  const handelBalnaceSelection = value => {
    setBalance(value.toString());
    settransactionbalance(value.toString()); //context
  };

  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  const handelUssdCodeRequest = (value, balan) => {
    if (value == null) {
      setUssdCodeRequest(null);
      settransactionussdcode(null); //context
    } else {
      let str_value = value.toString();
      var code = null;
      if (balan != null) {
        code = str_value.replace("amount", balan);
      } else {
        code = str_value;
      }

      setUssdCodeRequest(code);
      settransactionussdcode(code); //context
    }
  };

  const handelOfferSelection = value => {
    setSelectedOffer(value);
  };

  const handelUssdForModalresponse = (value, amount, item) => {
    setussdforModalresponse(value);
    settransactionussdcode(value); //context
    settransactionbalance(amount); //context
    setselectedchoise(item); //context

    sendUssdCodeModal(value, amount);
  };

  const handleOperationType = value => {
    setoperationType(value);
  };

  /**
   * get points
   *  */
  const [pointsForFlexy, setpointsForFlexy] = useState([]);
  const [pointsForOffers, setpointsForOffers] = useState([]);
  useEffect(() => {
    TWS.getPoints(networkOperator.current, user.token)
      .then(res => {
        setpointsForFlexy(res.ptsflexy);
        setpointsForOffers(res.ptsoffre);
      })
      .catch(() => {});
  }, []);
  /**
   * get ussd codes
   *  */

  useEffect(() => {
    setloadingussd(true);
    let a_offers = [];
    let a_flexy = [];
    let a_requtes = [];
    let a_samanet = [];
    TWS.getUssdCodes(networkOperator.current, user.token)
      .then(ussds => {
        ussds.filter(function(item) {
          if (item.type == "flexy") {
            a_flexy.push(item);
          }
          if (item.type == "demande") {
            a_requtes.push(item);
          }

          if (item.type == "offre") {
            a_offers.push(item);
          }
          if (item.type == "samanet") {
            a_samanet.push(item);
          }
        });
        setussdscodesFlexy(a_flexy);
        setussdscodesrequestOffer(a_requtes);
        setussdscodesOffre(a_offers);
        setussdscodesSamanet(a_samanet);

        setloadingussd(false);
      })
      .catch(() => {});
  }, []);
  /**
   * listen changes from firebase
   */
  useEffect(() => {
    if (is_sentRequest) {
      setdataloading(true);
      onSnapshot(doc(db, "responses", deviceId), doc => {
        // console.log("Current data: ", doc.data());
        if (doc.data().status == "true") {
          if (
            doc.data().response.length == 1 ||
            doc.data().response.length == 2
          ) {
            handelModalResponse(false);
            var transfertstatus = false;
            var msg = null;
            if (checksuccess(doc.data().response[0])) {
              transfertstatus = true;
            }
            msg = doc.data().response;
            updateStatusFinalChoise(transfertstatus, msg);
          } else {
            handelModalResponse(true);
            //console.log('***************');
            setEntities(doc.data());
            //console.log('response : ', documentSnapshot.data());
            setdataloading(false);
            updateStatus();
          }
        }
      });
    }
  }, [is_sentRequest]);

  /**
   * Update response status
   */
  const updateStatus = async () => {
    const docref = doc(db, "responses", deviceId);
    await updateDoc(docref, {
      status: "false"
    }).then(() => {
      setbuttondisabled(false);
      setbuttonloading(false);
      //console.log('data updated!');
    });
  };
  const updateStatusFinalChoise = async (transfertstatus, msg) => {
    const docref = doc(db, "responses", deviceId);
    await updateDoc(docref, {
      status: "false"
    }).then(() => {
      navigate("/transactionstatus", {
        state: {
          status: transfertstatus,
          msg: msg,
          operator:operator,
          isDjezzyFacture: false,
          phone:phone,
          amount:balance,
          name: CustomerName
        }
      });
      setbuttondisabled(false);
      setbuttonloading(false);
      //console.log('data updated!');
    });
  };

  /**
   * send USSD code
   */

  var s_ussd = null;
  const sendUssdCode = async () => {
    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(balance)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      setbuttondisabled(true);
      setbuttonloading(true);

      if (phone.length != 8) {
        seterrordialog(true);
        seterrordialogMessage("Veuillez remplir le numéro de téléphone");
      } else if (balance.length > 1) {
        if (parseInt(balance) >= 40) {
          sendUssdFlexyAndActivation();
        } else {
          seterrordialog(true);
          seterrordialogMessage(
            "Le montant doit être supérieur ou égal à 40DA"
          );
          setbuttondisabled(false);
          setbuttonloading(false);
        }
      } else {
        if (ussdCodeRequest == null) {
          s_ussd = ussdscodesrequestOffer[0].code.replace(
            "num",
            formatnetworkCode(networkOperatorCode.current) + phone
          );
          setussdforModalresponse(s_ussd);
        } else {
          s_ussd = ussdCodeRequest;
        }
        if (selectedoffer == null) {
          // handelModalResponse(true);
        }
        const docref = doc(db, "requests", deviceId);
        await updateDoc(docref, {
          client_id: deviceId,
          user_id: user.id,
          status: "true",
          ussd: s_ussd,
          operator: parseInt(operator),
          type: "demande offers",
          phone: phone,
          amount: balance
        }).then(() => {
          setIs_sentRequest(_old => _old + 1);
          //console.log('data sent!');
        });
      }
    }
  };

  /**
   * send choise modal
   */

  const sendUssdCodeModal = async (value, amount) => {
    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(amount)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant, merci de charger votre compte");
    } else {
      const docref = doc(db, "requests", deviceId);
      await updateDoc(docref, {
        client_id: deviceId,
        user_id: user.id,
        status: "true",
        ussd: value,
        operator: parseInt(operator),
        type: "offer activation",
        phone: phone,
        amount: amount
      }).then(() => {
        setIs_sentRequest(_old => _old + 1);
        //console.log('data sent!');
      });
    }
  };

  /**
   * send flexy request
   */

  const sendUssdFlexyAndActivation = async () => {
    if (ussdCodeRequest != null) {
      var n_ussd = ussdCodeRequest;
      if (ussdCodeRequest.includes("num")) {
        let tele = formatnetworkCode(networkOperatorCode.current) + phone;
        n_ussd = n_ussd.replace("num", tele);
      }
      setussdforModalresponse(n_ussd)
      const docref = doc(db, "requests", deviceId);
      await updateDoc(docref, {
        client_id: deviceId,
        user_id: user.id,
        status: "true",
        ussd: n_ussd,
        operator: parseInt(operator),
        type: operationType,
        phone: phone,
        amount: balance
      }).then(() => {
        setIs_sentRequest(_old => _old + 1);
        //console.log('data sent!');
      });
    } else {
      seterrordialog(true);
      seterrordialogMessage(
        "Une erreur est survenue, merci de réessayer ulterieurement"
      );
    }
  };

  /**
   * Operator selection
   */
  const networkOperator = useRef(
    operator == 1 ? "Mobilis" : operator == 2 ? "Djezzy" : "Ooredoo"
  );
  const networkOperatorCode = useRef(operator == 1 ? 6 : operator == 2 ? 7 : 5);

/**
 * handel selected contact
 */
const handelSelectedContact =(phone, name)=>{
  setPhone(phone)
  setCustomerName(name)
  setbuttondisabled(false);
  setNumberOffers(null)
}

/**
 * Offer description
 *
 */


  const [NumberOffers, setNumberOffers] = useState(null);

  const [selectedofferdesc, setselectedofferdesc] = useState(null);
const handelofferselectiondesc =(amount)=>{
  if (amount == null ||NumberOffers == null) {
    setselectedofferdesc(null)
  }else{
  setselectedofferdesc(null)  
  NumberOffers.simoffer.some(el => {
        if (parseInt(el.amount) == parseInt(amount)) {
          setselectedofferdesc(el.description.split(','))
         
        }
      })
    }
}

/**
 * handel Number Offer
 */

const handelNumberOffer =(offers) =>{
setNumberOffers(offers)

}


/**
 * get last transaction
 */
  const [lastTransaction, setlastTransaction] = useState('');
  const [is_duplicated, setis_duplicated] = useState(false);
  const [latestTransaction, setlatestTransaction] = useState([]);
  useEffect(() => {
    TWS.getlastTranas(user.id,user.token)
      .then(res => {
      
        setlastTransaction(res[0].phone.substring(2));
        setlatestTransaction(res)
      })
      .catch(() => {
       
      });
  }, []);


/**
 * local server
 */
const [isLocalServer, setisLocalServer] = useState(true);


/**
 * send ussd
 */
const SendUssdLocal  = async () => {
  setbuttondisabled(true);
  setbuttonloading(true);
  if (phone.length != 8) {
    seterrordialog(true);
    seterrordialogMessage("Veuillez remplir le numéro de téléphone");
    return;
  }

 if (balance.length > 1) {
 
  if (parseInt(balance) >= 40) {
    LocalFlexyAndActivation()
  } else {
    seterrordialog(true);
    seterrordialogMessage(
      "Le montant doit être supérieur ou égal à 40DA"
    );
    setbuttondisabled(false);
    setbuttonloading(false);
  }
} else {
  if (ussdCodeRequest == null) {
    s_ussd = ussdscodesrequestOffer[0].code.replace(
      "num",
      formatnetworkCode(networkOperatorCode.current) + phone
    );
    setussdforModalresponse(s_ussd);
  } else {
    s_ussd = ussdCodeRequest;
  }

  var data = {
    client_id: deviceId,
    user_id: user.id,
    status: "true",
    ussd: s_ussd,
    operator: parseInt(operator),
    type: "demande offers",
    phone: phone,
    amount: balance
  }

      fetch('http://localhost:5001/process', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json()) // Parse the JSON response
    .then(data => {
      checkResponseFromLocal(data.message)
        //alert('Message: ' + data.message + ', Result: ' + data.result); // Display the message and result
    })
    .catch((error) => {
      seterrordialog(true);
      seterrordialogMessage(
        "Une erreur est survenue, "+ error
      );
    });
    }

}


const LocalFlexyAndActivation = async () => {
  if (ussdCodeRequest != null) {
    var n_ussd = ussdCodeRequest;
    if (ussdCodeRequest.includes("num")) {
      let tele = formatnetworkCode(networkOperatorCode.current) + phone;
      n_ussd = n_ussd.replace("num", tele);
    }
    setussdforModalresponse(n_ussd)
    var data = {
      client_id: deviceId,
      user_id: user.id,
      status: "true",
      ussd: n_ussd,
      operator: parseInt(operator),
      type: operationType,
      phone: phone,
      amount: balance
    }

       fetch('http://localhost:5001/process', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
          
          //alert('Message: ' + data.message + ', Result: ' + data.result); // Display the message and result
          checkResponseFromLocal(data.message)
      })
      .catch((error) => {
          
          seterrordialog(true);
          seterrordialogMessage(
            "Une erreur est survenue, "+ error
          );
      });
    
  } else {
    seterrordialog(true);
    seterrordialogMessage(
      "Une erreur est survenue, merci de réessayer ulterieurement"
    );
  }
};


const checkResponseFromLocal =(response)=>{
 
    if (
      response.length == 1 ||
      response.length == 2
    ) {
      handelModalResponse(false);
      var transfertstatus = false;
     
      if (checksuccess(response[0])) {
        transfertstatus = true;
      }
      navigate("/transactionstatus", {
        state: {
          status: transfertstatus,
          msg: response[0],
          operator:operator,
          isDjezzyFacture: false,
          phone:phone,
          amount:balance,
          name: CustomerName
        }
      });
      setbuttondisabled(false);
      setbuttonloading(false);
    } else {
      //handelModalResponse(true);
      //setEntities(doc.data());
       console.log('other case ....')
      setdataloading(false);
      
    }
  
}

  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />

      {/* main */}
      <div className="main-content pb-5 flexy-page">
        <div className=" container">
       
          <h1 className="pagetitle_intern text-center">
            {networkOperator.current}
          </h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
           
          <ContactDrawer  handelSelectedContact={handelSelectedContact} operator={operator}/>
          <TransactionsDrawer   data={latestTransaction}/>

          
          <div className="row">
            <div className="col-md-12 col-lg-6">
              {/* inputs */}
             {is_duplicated &&  (<Alert  className="check-transaction" severity="warning">يرجى التأكد من هذه العملية ، لقد قمت بارسال رصيد في هذا الرقم قبل قليل</Alert>)}
              <Inputs
                handleNumberInputChange={handleNumberInputChange}
                networkOperatorCode={networkOperatorCode}
                handleBlanceInputChange={handleBlanceInputChange}
                enableinput={enablebalanceInput}
                balance={balance}
                selectedoffer={selectedoffer}
                userId={user.id}
                phone={phone}
                network={networkOperator.current}
                token={user.token}
                handelCustomerName={handelCustomerName}
                name={CustomerName}
                handelNumberOffer={handelNumberOffer}
              />
              <div className="text-center">
                <FloatingButton
                  sendUssdCode={sendUssdCode}
                  color={Colors.BACKGROUND_PRIMARY}
                  loading={buttonloading}
                  disabled={buttondisabled}
                />
              </div>
              <Offerdescription data={ selectedofferdesc}/>
            </div>
            <div className="col-md-12 col-lg-6 ">
              {/* offers & flexy */}
              <Offers
                offers={ussdscodesoffer}
                flexy={ussdscodesflexy}
                samanet={ussdscodessamanet}
                bgcolor={
                  operator == 1
                    ? Colors.MOBILIS
                    : operator == 2
                    ? Colors.DJEZZY
                    : Colors.OOREDDOO
                }
                handelbalnaceselection={handelBalnaceSelection}
                handelussdcoderequest={handelUssdCodeRequest}
                handelofferselection={handelOfferSelection}
                handleoperationtype={handleOperationType}
                operator={operator}
                pointsflexy={pointsForFlexy}
                pointsoffers={pointsForOffers}
                handelofferselectiondesc={handelofferselectiondesc}
                NumberOffers={NumberOffers}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomDrawerModal
        dataloading={dataloading}
        showmodelresponse={ShowModelResponse}
        handelmodalresponse={handelModalResponse}
        data={entities}
        handelussdformodalresponse={handelUssdForModalresponse}
        ussd={ussdforModalresponse}
        operator={operator}
      />
    </div>
  );
};

export default Flexy;
