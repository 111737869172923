import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from '@mui/material/Typography';

import PersonIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';

import FilledInput from '@mui/material/FilledInput';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { TWS } from "../../services/WebServices";

export default function AddDebt({
  user, isOpen,handelDebtModal, phone, customer, amount,operator
}) {

const [name, setName] = useState(customer);
 const [showErrors, setshowErrors] = useState(false);
  const [sucessStatus, setSuccessStatus] = useState(false);




 function validation(){
 
  if(phone === null || name === null || phone.length < 10 ) {
    setshowErrors(true)
    }else{
        postDebt()
      //postCustomer()
    }
}
  const postCustomer = async () => {
    
    try {
      
      await TWS.postCustomer(name,phone,user.id,user.token).then(res => {
       setSuccessStatus(true)
        setshowErrors(false)
      });
  
    } catch (errors) {
      setshowErrors(true)
      //alert("une erreur est survenue veuillez réessayer ultérieurement");
    }
  
}

const postDebt = async () => {
   
    var network = operator == 1 ? 'Mobilis' : operator == 2 ? 'Djezzy' : 'Ooredoo';
    var comment=""
    try {
       
      await TWS.postDebts(user.token,user.id,name,amount,phone,network,comment).then(res => {
        if(res = 'success'){
        setSuccessStatus(true)
        setshowErrors(false)
        }
      });
  
    } catch (errors) {
      setshowErrors(true)
      //alert("une erreur est survenue veuillez réessayer ultérieurement");
    }
  
}

  return (
    <Dialog
      open={isOpen}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle>{"Ajouter aux Dettes"}</DialogTitle>
      
      <DialogContent>
        
       {sucessStatus ? ( <Alert severity="success">Dette ajoutée avec succès</Alert>) :(
        
         <FormControl sx={{ mt: 0, width: '100%' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-name">Nom du client</InputLabel>
          <FilledInput
            id="filled-adornment-name"
            type={'text'}
            value={name}
            onChange={event => {
              setName(event.target.value);
            }}
            
           
            endAdornment={
              <InputAdornment position="end">
                 <PersonIcon /> 
              </InputAdornment>
            }
          />
          
        </FormControl>)}
      </DialogContent>
      <DialogActions>
        <Button
          className="continue_btn"
          disabled={sucessStatus}
          onClick={() => {
            validation();
          }}
        >
          Enregistrer
        </Button>
        <Button
          className="annuler_btn"
          onClick={() => {
            handelDebtModal();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
