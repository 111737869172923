import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import { TWS } from "../../services/WebServices";
import ShopProductCard from "../../components/store/ShopProductCard";
import SendLoading from "../../components/flexy/SendLoading";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShopCart from "../../components/store/ShopCart";
import {CalculateCartTotal} from '../../helpers'
import SuccessDialog from "../../components/SuccessDialog";
import TextField from "@mui/material/TextField";


const Storepage = props => {
  const { t } = useTranslation();
  const { user, updateUserInfos } = useContext(UserContext);

  const [products, setProducts] = useState([]);
  const [loading, setloading] = useState(true);
  const [modalCart, setModalCart] = useState(false);
  const [CartProducts, setCartProducts] = useState([]);
  const [CartProductsQy, setCartProductsQty] = useState([]);


    /**
   * save products
   */
  const asyncLocalStorage = {
     setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    },
    removeItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.removeItem(key, value);
      });
    },
  };

  const saveShopCartProducts = async (items)=>{
    asyncLocalStorage.setItem("cart", JSON.stringify(items));
  }

  const getShopCartProducts = async () => {
    asyncLocalStorage.getItem("cart").then(resp => {
      if (resp != null) {
        var products = JSON.parse(resp)
        setCartProducts(products)
      
        
        for (var i = 0; i < products.length; i++) {
          var cartProduct = {
              id: products[i].id,
              qty: 1 // Default quantity, you can change this as needed
          };
          CartProductsQy.push(cartProduct);
      }
      }
    });
  }
 useEffect(() => {
   getShopCartProducts()

  }, []);
  const handleProductsCart = (product) => {
    CartProducts.push(product);
    CartProductsQy.push({
      'id':product.id,
      'qty':1
    })

    saveShopCartProducts(CartProducts)

  };


  const editProductQuantity =(productId, newQuantity) =>{
    const index = CartProductsQy.findIndex(item => item.id === productId);
    if (index !== -1) {
        CartProductsQy[index].qty = newQuantity;
        
    } 
}

  const removeProductFromCart =(productId) =>{
    var CartProductsQty = CartProductsQy.filter(function(cartProduct) {
    return cartProduct.id !== productId;
    });

    var products = CartProducts.filter(function(cartProduct) {
    return cartProduct.id !== productId;
    });
    setCartProducts(products)
    saveShopCartProducts(products)
    setCartProductsQty(CartProductsQty)
}

  const handleCart = () => {
    setModalCart(true);
  };

  const handleCartClose = () => {
    setModalCart(false);
  };

  useEffect(() => {

  getProducts()

    }, []);

function getProducts(){
  setloading(true)
    TWS.StoreProductList(user.token)
      .then(res => {
        setProducts(res);
       
        setloading(false)
 
      })
      .catch(() => {});
}


/**
 * SEND ORDER
 */

function sendOrder(){
  var comment = ''
  var amount = CalculateCartTotal(CartProducts,CartProductsQy);
 
   TWS.postCartPoroducts(user.token,user.id,amount, comment,CartProductsQy)
      .then(res => {
        asyncLocalStorage.removeItem("cart");
        setCartProducts([])
        setCartProductsQty([])
        saveShopCartProducts([])
        handleCartClose()
        handelsucessdialog()
      })
      .catch(() => {});
}
const [sucessStatus, setSuccessStatus] = useState(false);
const handelsucessdialog = () => {
  setSuccessStatus(!sucessStatus)
};



/**
 * serach
 * 
 */
function searchProduct(val) {
  if (val === '') {
    getProducts();
  } else {
    const query = val.toLowerCase();

    const result = products.filter(element => {
      const nameLowerCase = element.name.toLowerCase();
      return nameLowerCase.includes(query); // Search in the entire product name
    });

    setProducts(result);

    // Optional: Display a message if no products are found
    if (result.length === 0) {
      //console.log('No products found');
    }
  }
}
  return (
    <div className="wrapper">
      <Navbar active="store" props={props} data={user} />
      {/* main */}
      <Button className="shop_cart" variant="contained" onClick={handleCart} startIcon={<ShoppingCartIcon />}>
                Panier ({CartProducts.length})
      </Button>
      <div className="main-content pb-2">
        <div className="container ">
          <h1 className="pagetitle_intern text-center">RedMed Accessoires</h1>
         {/*  <h1 className="pagetitle_intern text-center">Bientôt ...</h1>
          <h3 className=" text-center">Restez Branchés</h3>*/}
         
          <SendLoading loading={loading} />
          
          <div className="row store_product_serach">
            <TextField
                
                placeholder="rechecher"
                type="text"
                
                  InputLabelProps={{
                  shrink: true,
                                  
                  }}
                  onChange={event => {
                         searchProduct(event.target.value);
                    }}
              />
          <p >Ex: Kitman, Chargeur, Cable type c ...</p>
        </div>
         
          <div className="row text-center">
            {products.map((item, index) => {
              return(  <div className="col-md-3 mb-2"  key={index}>
                <ShopProductCard  data={item} handleproductscart={handleProductsCart} />
                </div>)
             
            })}
         </div>
         
        </div>
      </div>
      <Dialog
       fullWidth={true}
        maxWidth={false}
        open={modalCart}
        onClose={handleCartClose}
        aria-labelledby="cart-dialog"
        aria-describedby="cart-dialog-description"
      >
        
        <DialogContent>
          <ShopCart data={CartProducts} itemqty={CartProductsQy} editproductquantity={editProductQuantity} removeproduct={removeProductFromCart}/>
        </DialogContent>
        <DialogActions>
          <Button  variant="contained" className="confirm_btn" onClick={()=>{
              sendOrder()
          }} autoFocus>
            Envoyer
          </Button>
          <Button  variant="contained" className="annuler_btn" onClick={handleCartClose} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessDialog title={'Félicitations !'} message='Votre commande est envoyés avec succès' handelsucessdialog={handelsucessdialog} status={sucessStatus} />

    </div>
  );
};

export default Storepage;
