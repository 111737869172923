import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import Flexy from "../pages/Flexy";
import IdoomADSL from "../pages/IdoomADSL";
import Transactions from "../pages/Transactions";
import TransactionStatus from "../pages/TransactionStatus";
import Notfound from "../pages/Notfound";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserProvider from "../providers/UserProvider";
import TransactionsManager from "../providers/TransactionsManager";
import DjezzyFacture from "../pages/DjezzyFacture";
import Idoom4G from "../pages/Idoom4G";
import Rechargement from "../pages/Rechargement";
import PrepaidCards from "../pages/PrepaidCards";
import Settings from "../pages/settings/Settings";
import Store from "../pages/store/Storepage";
import Recharge from "../pages/AccountRecharge/Recharge";
import Tuto from "../pages/tuto/Tuto";
import GamesCards from "../pages/games/GamesCards";
import Debts from '../pages/debts/Debtspage';

function AppNavigation() {
  return (
    <UserProvider>
      <TransactionsManager>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} name="home" />
            <Route
              exact
              path="/flexy/:operator/:isnewoperation"
              element={<Flexy />}
              name="flexy"
            />
            <Route
              exact
              path="/djezzyfacture/:operator/:isnewoperation"
              element={<DjezzyFacture />}
              name="djezzyfacture"
            />
            <Route exact path="/idoom" element={<IdoomADSL />} name="idoom" />
            <Route
              exact
              path="/idoomfourg"
              element={<Idoom4G />}
              name="idoomfourg"
            />
            <Route
              exact
              path="/transactions"
              element={<Transactions />}
              name="transactions"
            />
            <Route
              exact
              path="/transactionstatus"
              element={<TransactionStatus />}
              name="transactionstatus"
            />
            <Route
              exact
              path="/store"
              element={<Store />}
              name="store"
            />
            <Route
              exact
              path="/rechargements"
              element={<Rechargement />}
              name="rechargements"
            />
            <Route
              exact
              path="/prepaidcards/:operator"
              element={<PrepaidCards />}
              name="prepaidcards"
            />
            <Route
              exact
              path="/gamescards/:operator"
              element={<GamesCards />}
              name="gamescards"
            />
            <Route
              exact
              path="/settings"
              element={<Settings />}
              name="settings"
            />

            <Route
              exact
              path="/tutorials"
              element={<Tuto />}
              name="Tuto"
            />

            <Route
              exact
              path="/accountrechargement"
              element={<Recharge />}
              name="accountrechargement"
            />

          <Route
              exact
              path="/debts"
              element={<Debts />}
              name="debts"
            />

            <Route path="/404" element={<Notfound />} />

            <Route path="*" element={<Navigate replace to="/404" />} />
          </Routes>
        </BrowserRouter>
      </TransactionsManager>
    </UserProvider>
  );
}

export default AppNavigation;
