import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import DehazeIcon from "@mui/icons-material/Dehaze";
import StoreIcon from '@mui/icons-material/Store';
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../providers/AuthStack";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { UserContext } from "../providers/UserProvider";
import CloseIcon from '@mui/icons-material/Close';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
export default function Navbar({ props, data, active }) {
  const [isActive, setIsActive] = useState({
    home: false,
    transactions: false,
    rechargements: false,
    contact: false,
    setings:false,
    tuto:false,
    debt:false
  });
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { authDispatch } = useContext(AuthContext);
  const { user, ooredoostatus,
    mobilisstatus,
    djezzystatus,} = useContext(UserContext);
  useEffect(() => {
    active == "home"
      ? setIsActive({
          home: true,
          transactions: false,
          rechargements: false,
          store:false,
          contact: false,
          settings:false,
           tuto:false,
           debt:false
        })
      : active == "transactions"
      ? setIsActive({
          home: false,
          transactions: true,
          rechargements: false,
          store:false,
          contact: false,
          settings:false,
           tuto:false,
           debt:false
        })
      : active == "rechargements"
      ? setIsActive({
          home: false,
          transactions: false,
          rechargements: true, 
          store:false,
          contact: false,
          settings:false,
           tuto:false,
           debt:false
        })
        : active == "store"
        ? setIsActive({
            home: false,
            transactions: false,
            rechargements: false,
            store:true,
            contact: false,
            settings:false,
            tuto:false,
            debt:false
          })
        : active == "settings"
      ? setIsActive({
          home: false,
          transactions: false,
          rechargements: false,
          store:false,
          contact: false,
          settings:true,
          tuto:false,
          debt:false
       
        })
      : active == "tutorials"
      ? setIsActive({
          home: false,
          transactions: false,
          rechargements: false,
          store:false,
          contact: false,
          settings:false,
          tuto:true,
          debt:false
        })
      : active == "debts"
      ? setIsActive({
          home: false,
          transactions: false,
          rechargements: false,
          store:false,
          contact: false,
          settings:false,
          tuto:false,
          debt:true
        })
      : setIsActive({
          home: false,
          transactions: false,
          rechargements: false,
          store:false,
          contact: true,
          settings:false,
          tuto:false,
          debt:false
        });

      
  }, []);

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-dark ${
          isOpen ? "collapsed" : ""
        }`}
      >
        <div className="container-fluid">
          <div className="navbar-brand-holder d-flex">
            <IconButton
              styles={{
                icon: { color: "white", fontSize: 72 }
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
             {isOpen ? <CloseIcon/> : <DehazeIcon />}
            </IconButton>
            
            <a className="navbar-brand mx-2" href="#">
              {" "}
              REDMED - TELECOM
            </a>
          </div>
          <div className="navbar_right">
            { mobilisstatus && <a href="/flexy/1/true">Mobilis</a>}
            {ooredoostatus && <a href="/flexy/3/true">Ooredoo</a>}
           {djezzystatus && <a href="/flexy/2/true">Djezzy</a>}
          {/*<a href="/accountrechargement" >Rechargement</a>*/} 
          
          </div>
        </div>
      </nav>

      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="logo">
          <img src="/images/avatar.png" alt="..." />
          <h5 className="username">{data.name}</h5>
          <span className="userPhone">{data.phone}</span>
          <div className="d-flex justify-content-between  user-infos mt-4">
            <span className="user-infos-span">
              {t("solde")} : {data.activebalance[0].balance} DA
            </span>
            <span className="user-infos-span">
              {t("points")} : {data.activebalance[0].pts}
            </span>
          </div>
        </div>
        <ul>
          <Link to="/" className="d-flex justify-content-center">
            <li
              className={`nav-item nav-item-custum  ${
                isActive.home ? "active" : ""
              }`}
            >
              <HomeIcon />
              <span>{t("home")}</span>
            </li>
          </Link>
          <Link to="/transactions" className="d-flex justify-content-center">
            <li
              className={`nav-item nav-item-custum ${
                isActive.transactions ? "active" : ""
              }`}
            >
              <HistoryIcon />
              <span>{t("transactions")}</span>
            </li>
          </Link>
          <Link to="/rechargements" className="d-flex justify-content-center">
            <li
              className={`nav-item nav-item-custum ${
                isActive.rechargements ? "active" : ""
              }`}
            >
              <CreditScoreIcon />
              <span>Rechargements</span>
            </li>
          </Link>
          <Link to="/debts" className="d-flex justify-content-center">
          <li className={`nav-item  ${isActive.debt ? "active" : ""}`}>
              <BookmarkAddedIcon />
              <span>{"Dettes"}</span>
            </li>
            </Link>
            <Link to="/store" className="d-flex justify-content-center">
            <li className={`nav-item  ${isActive.store ? "active" : ""}`}>
              <StoreIcon />
              <span>{"Marketplace"}</span>
            </li>
            </Link>
          <Link to="/settings" className="d-flex justify-content-center">
            <li className={`nav-item  ${isActive.settings ? "active" : ""}`}>
              <SettingsIcon />
              <span>{"Paramètres"}</span>
            </li>
          </Link>

           <Link to="/tutorials" className="d-flex justify-content-center">
            <li className={`nav-item  ${isActive.tuto ? "active" : ""}`}>
              <VideoLibraryIcon />
              <span>{"Tuto"}</span>
            </li>
          </Link>
        
          
          <Link className="d-flex justify-content-center">
            <li
              className="nav-item nav-item-custum"
              onClick={() => authDispatch({ type: "SIGN_OUT" })}
            >
              <LogoutIcon />

              <span>{t("logout")}</span>
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}
