import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import { nFormatter } from "../../helpers";
export default function CustomerRechargeSim({data,responsable}) {



 
  return (
    <div className="col-md-12 mb-3 "  >
      
      <div className={"card rechargement_card transaction-item " } >
        
      <div className="row ">
        
            <p className="text-end mb-0">{data.created_at}</p>
          </div>
        <div className={"card-body recharge_pro  sim_hist "}>
         
          <div className=" row d-flex align-items-center justify-content-between">
            <div className="text-center col-sm-3">
              <div className="recharge_item  text-center">
              <p className="recharge_item_name primary">{responsable}</p>
              <p className="recharge_item_pro_title">Expéditeur</p>
               
              </div>
            </div>
            <div className="text-center col-sm-3">
              <div className="recharge_item text-center">
              <p className="recharge_item_pro_amount primary"> {nFormatter(data.amount)} DA</p>
              <p className="recharge_item_pro_title">Rechargement</p>
              </div>
            </div>

            <div className="text-center col-sm-3">
              <div className="recharge_item text-center">
              <p className="recharge_item_pro_amount primary">{data.number}</p>
              <p className="recharge_item_pro_title ">Numéro</p>
              </div>
            </div>

         
          </div>
          <div className="row sim_resposne">
          <p >{data.response}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
