import React, { useState} from "react";


  import Button from '@mui/material/Button';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  import Slide from '@mui/material/Slide';
  import CloseIcon from '@mui/icons-material/Close';
  import IconButton from '@mui/material/IconButton';
  import {
    doc,
    setDoc,
  } from "firebase/firestore";
  import { db } from "../../../services/Firestore";
  import { TWS } from "../../../services/WebServices";


    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
    
  
  
  export default function SendPin({OpenPinModal,handleClickClosePinModal, clientId,clientName,deviceId,clientPhone,responsibleId,responsibleToken}) {
    
   /**
   * sen via sms
   */

    const [isent, setIssent] = useState(false);
    const [loadingPin, setloadingPin] = useState(false);
    const [nopin, setnopin] = useState(false);

  const sendSMS = async (pin) => {
    const docref = doc(db, "smsrequests", deviceId);
    await setDoc(docref, {
      client_id: deviceId,
      user_id: responsibleId,
      status: "true",
      phone: clientPhone,
      code: "Votre Code : "+pin,
      type: "sms"
    }).then(() => {
      setIssent(true)
    });
  };

function retreivePin(){
  setloadingPin(true)
  TWS.getPin(clientId, responsibleToken)
      .then(res => {
       
       if (res.pin) {
        sendSMS(res.pin)
       }else{
        setnopin(true)
       }
       setloadingPin(false)
      })
      .catch(() => {
        setnopin(true)
      });
}

    return (
        <Dialog
        open={OpenPinModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClickClosePinModal}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          padding:0,
          overflow:'hidden'
        }}
        >
          <div className="d-flex flex-row-reverse mt-4 mx-4">
              <IconButton aria-label="delete" onClick={()=>{
                handleClickClosePinModal()
                setloadingPin(false)
                setIssent(false)
                setnopin(false)
              }} className="close_btn" >
                <CloseIcon />
              </IconButton>
          </div>
        <DialogTitle sx={{fontSize:16, textAlign:'center'}}>{'Transmettez le code PIN à '+ clientName }</DialogTitle>
       {loadingPin && <DialogContent sx={{fontSize:13, textAlign:'center'}}>
        <span>Récupération du code Pin ....</span>
       </DialogContent>}
       {nopin && <DialogContent sx={{fontSize:13, textAlign:'center', color:'red'}}>
        <span>Auccun PIN trouvé !</span>
       </DialogContent>}
        <DialogActions sx={{ padding:1 ,justifyContent:'center'}}>
          <Button  variant="contained" className={isent ? "confirm_btn_green":"confirm_btn"} disabled={isent} onClick={()=>{
            retreivePin()
            }}>{isent ? 'PIN Envoyé':'Envoyer'}</Button>
        
        </DialogActions>
        </Dialog>
    );
  }